import {UyapRequest} from "./UyapRequest";
import {ResponseType} from "axios";
import JSZip from "jszip";

export interface IcraTalepEvrakHazirlaTalep {
    params: IIcraTalepEvrakHazirlaParamsData,
    responseType: "blob"
}

export interface IIcraTalepEvrakHazirlaParamsData {
    dosyaId: string,
    filename: string,
    kisiKurumId: number,
    talepBilgileri: string
}

export default class IcraTalepEvrakHazirla extends UyapRequest<IcraTalepEvrakHazirlaTalep, Blob> {
    delayTime = 1000;

    getMethod(): "POST" | "GET" {
        return "POST";
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "_"
        };
    }

    getMockResponse(): any {
        return new Blob();
    }

    getResponseType(): ResponseType {
        return 'blob';
    }

    getUrl(): string {
        return this.ImzaClientAddr + "getIcraTalepEvrakHazirla.uyap";
    }

    async parse(data: Blob): Promise<Blob> {
        return new Promise((resolve, reject) => {
            let blobData = data;
            let reader = new FileReader();
            reader.readAsText(data); //udf base64 ceviriliyor..
            reader.onloadend = function () {
                let textContent: any = reader.result;
                if (textContent.substr(0, 2) == "PK") {
                    console.log("RESOLVE ZIP");
                    return resolve(blobData);
                } else {
                    /**
                     * 08/04/2019
                     * uyap güncellemesi ile
                     * GELEN UDF zip olarak gelmiyor artık gelen xml datası mı diye text e çevirilip kontrol ediliyor.
                     * eğer zip olarak gelirse X tarihte ona göre de kontrol koyuldu.
                     * PK gelen data zip mi değilmi kontrolüdür..
                     * Emre
                     * @return zip blob data;
                     *
                     */
                    let zip = new JSZip();
                    zip.file("content.xml", blobData);
                    zip.generateAsync({
                        type: "blob",
                        compression: "DEFLATE",
                        compressionOptions: {
                            level: 6
                        }
                    }).then(function (content) {
                        let blob = new Blob([content], {type: "application/octet-stream"});//udf type ına çeviriliyor.
                        console.log("UDF CONVERTING BLOB", blob);
                        return resolve(blob);
                    }).catch(function (err) {
                        return reject(err);
                    });
                }
            }

        })
    }

}