import {UyapRequest} from "./UyapRequest";

export interface TamamlanmayanDosyalarTalep {
    dosyaTurKod: TamamlanmayanDosyaTurKod
}

export enum TamamlanmayanDosyaTurKod {
    NORMAL = 35,
    MTS = 394
}

export type TamamlanmayanDosyalarCevap = TamamlanmayanDosyalarRow[];

export interface TamamlanmayanDosyalarRow {
    birimID: string,
    birimAdi: string,
    dosyaID: string,
    dosyaNo?: string,
    dosyaAcilisTarihi: string,
    taraflar: string,
    sonIslemTarihi: string,
    surecNo: number,                      // 1: vekalet yok, 3: açılmaya/ödemeye hazır
    iliskiliDosyaVarmi: string,
    tebligatGonderimAktif: string
}


export class TamamlanmayanDosyalar extends UyapRequest<TamamlanmayanDosyalarTalep, TamamlanmayanDosyalarCevap> {
    delayTime = 1000;

    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "_"
        };
    }

    getMockResponse(): any {
        return [{"birimID":"1000946","birimAdi":"Ankara 6. Genel İcra Dairesi","dosyaID":"Sktp+bC8TAi+X24mOFIl+QuIll0pAhBMmtTpOCQB0+Rhbf@O98pOtLR3tFZ1KU81","dosyaAcilisTarihi":"May 13, 2024 12:03:40 PM","taraflar":"ALACAKLI MURAT KAMİL ÖZKABAŞ/ BORÇLU VE MÜFLİS CANER DİKİCİ","sonIslemTarihi":"May 13, 2024 12:03:40 PM","surecNo":1,"iliskiliDosyaVarmi":"H","tebligatGonderimAktif":"E"},{"birimID":"1011344","birimAdi":"İzmir 18. İcra Dairesi","dosyaID":"C8I4D0HXarC1905y@q4RmbIAPCDlRDV3tv5HKCbWh77ewOh@9AEEBXTuHI0I9HA@","dosyaAcilisTarihi":"May 9, 2024 10:43:06 AM","taraflar":"ALACAKLI ORCAN MOTORLU ARAÇLAR TİCARET LİMİTED ŞİRKETİ / BORÇLU VE MÜFLİS AYSUN KÖSE/ BORÇLU VE MÜFLİS GÖKTUĞ GÜRGEN","sonIslemTarihi":"May 9, 2024 10:43:07 AM","surecNo":1,"iliskiliDosyaVarmi":"H","tebligatGonderimAktif":"E"},{"birimID":"1039299","birimAdi":"Ankara Batı İcra Dairesi","dosyaID":"R@12e8Fv@wiA7SIq398YcX1mx6OpAuUvlMfAesOiOuAVntLhKY@AT6McaP1NivXL","dosyaAcilisTarihi":"May 13, 2024 2:37:54 PM","taraflar":"ALACAKLI CANER DİKİCİ/ BORÇLU VE MÜFLİS ÖMER ŞEN","sonIslemTarihi":"May 13, 2024 2:37:54 PM","surecNo":1,"iliskiliDosyaVarmi":"H","tebligatGonderimAktif":"E"}];
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'tamamlanmayanDosyalar_brd.ajx';
    }

    async parse(data: any): Promise<TamamlanmayanDosyalarCevap> {
        return data.map((row: any) => {
            row.sonIslemTarihi = new Date(row.sonIslemTarihi);
            row.dosyaAcilisTarihi = new Date(row.dosyaAcilisTarihi);
            return row;
        })
    }

}

export default TamamlanmayanDosyalar;
