import app from "@/main";
import {Mixins} from "../Mixins";
import Args from "@/plugins/uyap-plugin/takip_ac/parser/Args";

export default class Adres {
    ilKodu: string = "";
    ilceKodu: string = "";
    adresTuru: string = "";
    adresTuruAciklama: string = "";
    adres: string = "";
    ilAdi: string = "";
    ilceAdi: string = "";
    postaKodu: string = "";
    kisiKurumID!: any;

    static fromJson(jsonData: any): Adres {
        return Args.staticFromJson(jsonData,new Adres());
    }

    static async parse(adresNode: HTMLUnknownElement | Element, isMts: boolean = false): Promise<Adres> {
        let adres = new Adres();
        if (adresNode) {
            adres.ilKodu = Mixins.nullUndefCleaner(adresNode.getAttribute("ilkodu"));
            adres.ilceKodu = Mixins.nullUndefCleaner(adresNode.getAttribute("ilcekodu"));
            adres.adresTuru = Mixins.nullUndefCleaner(adresNode.getAttribute("adresturu"));
            adres.adresTuruAciklama = Mixins.nullUndefCleaner(adresNode.getAttribute("adresturuaciklama"));
            adres.adres = Mixins.nullUndefCleaner(adresNode.getAttribute("adres"));
            adres.ilAdi = Mixins.nullUndefCleaner(adresNode.getAttribute("il"));
            adres.ilceAdi = Mixins.nullUndefCleaner(adresNode.getAttribute("ilce"));
            adres.postaKodu = Mixins.nullUndefCleaner(adresNode.getAttribute("postakodu"));
            // TODO iller ilceler state e eklenip burda kullanilabilir..
            if (adres.ilKodu == null || adres.ilceKodu == null || adres.ilKodu == "" || adres.ilceKodu == "") {
                if (adres.ilAdi && adres.ilceAdi) {
                    let illerIlceler = await app.$uyap.IllerIlceler().run({});
                    let ilObj = illerIlceler.find(v => v.ad == app.$uyapHelper.turkishToUpper(adres.ilAdi));
                    if (ilObj != undefined) {
                        adres.ilKodu = ilObj.il;
                        let ilceObj = ilObj.ilceler.find((v: any) => v.ilcead.indexOf(app.$uyapHelper.turkishToUpper(adres.ilceAdi)) >= 0);
                        if (ilceObj != undefined) {
                            adres.ilceKodu = ilceObj.ilcekod;
                        }
                    }
                }
            }
        }
        return adres;
    }

    toUyapJson() {
        return this;
    }

    mtsToUyapJson() {
        return {
            ilKodu: this.ilKodu,
            ilAdi: this.ilAdi,
            ilceAdi: this.ilceAdi,
            ilceKodu: this.ilceKodu,
            adres: this.adres,
        }
    }
}

